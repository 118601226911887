<template>
    <div class="sidebar">
        <el-menu
            class="sidebar-el-menu"
            :default-active="onRoutes"
            :collapse="collapse"
            background-color="#732018"
            text-color="#bfcbd9"
            active-text-color="#20a0ff"
            unique-opened
            router
        >
            <template v-for="item in items">
                <template v-if="item.subs">
                    <el-submenu :index="item.index" :key="item.index">
                        <template slot="title">
                            <i :class="item.icon"></i>
                            <span slot="title">{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu
                                v-if="subItem.subs"
                                :index="subItem.index"
                                :key="subItem.index"
                            >
                                <template slot="title">{{ subItem.title }}</template>
                                <el-menu-item
                                    v-for="(threeItem,i) in subItem.subs"
                                    :key="i"
                                    :index="threeItem.index"
                                >{{ threeItem.title }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item
                                v-else
                                :index="subItem.index"
                                :key="subItem.index"
                            >{{ subItem.title }}</el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <span slot="title">{{ item.title }}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
import bus from '../common/bus';
export default {
    data() {
        return {
            collapse: false,
            items: [

             // {
             //        icon: 'el-icon-s-home',
             //        index: 'notice',
             //        title: '系统首页'
             //    },
                {
                    icon: 'el-icon-s-opportunity',
                    index: 'medical',
                    title: '计划管理'
                },



                {
                    icon: 'el-icon-s-opportunity',
                    index: 'anonymity-manage',
                    title: '匿名信箱管理'
                },  {
                    icon: 'el-icon-s-opportunity',
                    index: 'academic-manage',
                    title: '学术报告管理'
                }, {
                    icon: 'el-icon-s-opportunity',
                    index: 'interview',
                    title: '面谈管理'
                },

                {
                    icon: 'el-icon-s-order',
                    title: '问卷管理',
                    index: 'publish',
                    subs: [
                        {
                            icon: 'el-icon-s-opportunity',
                            index: 'publish',
                            title: '填报管理'
                        },{
                            icon: 'el-icon-s-opportunity',
                            index: 'questionnaire',
                            title: '问卷管理'
                        },
                    ]
                },
                {
                    icon: 'el-icon-s-order',
                    title: '组会管理',
                    index: 'group',
                    subs: [
                        {
                            icon: 'el-icon-s-opportunity',
                            index: 'group-manage',
                            title: '组会管理'
                        },  {
                            icon: 'el-icon-s-opportunity',
                            index: 'group-check',
                            title: '组会报告审核'
                        },
                    ]
                },


                {
                    icon: 'el-icon-s-order',
                    title: '权限管理',
                    index: '',
                    subs: [
                        {
                            icon: 'el-icon-s-home',
                            index: 'roles',
                            title: '角色管理'
                        },{
                            icon: 'el-icon-s-opportunity',
                            index: 'admin',
                            title: '用户管理'
                        },{
                            icon: 'el-icon-s-custom',
                            index: 'menu',
                            title: '菜单管理'
                        },
                    ]
                }
            ]
        };
    },
    computed: {
        onRoutes() {
            return this.$route.path.replace('/', '');
        }
    },
    created() {
        // 通过 Event Bus 进行组件间通信，来折叠侧边栏
        bus.$on('collapse', msg => {
            this.collapse = msg;
            console.log(this.collapse);
            bus.$emit('collapse-content', msg);
        });
    }
};
</script>

<style scoped>
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
.sidebar > ul {
    height: 100%;
}
</style>
